.gallery
  padding-top 1rem
  &--item
    line-height 0
    lost-waffle 1/3 3 2.1rem
    +below('s')
      lost-waffle 1/1
    img
      width: 100%
    &--title
      display inline-block
      padding-top 10px
      font-size 1rem
      i
        transform rotate(90deg)


.copyright
  margin-top 2rem
  lost-column 1/1
  display block