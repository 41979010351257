.lead
    min-height 500px
    height 60vh
    background no-repeat center 45%/cover

    .wrapper
        height 100%

    &--small
        height 25vh
        background-position center 35%
        min-height 320px

        +below('s')
            min-height 180px
