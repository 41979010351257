.beige, .beige-hover:hover, .beige-hover.active
  color $beige
  &-bg
    background-color $beige
.blue, .blue-hover:hover, .blue-hover.active
  color $blue
  &-bg
    background-color $blue
.green, .green-hover:hover, .green-hover.active
  color $green
  &-bg
    background-color $green
.orange, .orange-hover:hover, .orange-hover.active
  color $orange
  &-bg
    background-color $orange
.violet, .violet-hover:hover, .violet-hover.active
  color $violet
  &-bg
    background-color $violet
.black, .black-hover:hover, .black-hover.active
  color black
  &-bg
    background-color black