.footer
    margin-top 1em
    lost-utility clearfix
    color $beige
    .up-link
        lost-column 1/1
        display block
        padding 2em 0 1em

    .contact
        lost-column 1/1
        padding 1em 0 0
        a
            color $beige
            &:hover
                color $violet