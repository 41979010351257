.exhibition
  &--media
    lost-column 1/1
    margin 1rem 0 1.4rem
    img, .video-js, iframe
      lost-column 2/3
      +below('s')
        lost-column 1/1
    .video-js
      overflow hidden
    .video-js
      height 500px
      +below('l')
        height 400px
      +below('m')
        height 300px
      +below('s')
        height 200px
      .vjs-big-play-button
        left 50%
        top 50%
        transform: translate(-50%, -50%);
      .vjs-poster
        background-size cover
    iframe
      height 436px
      +below('l')
        height 362px
      +below('m')
        height 249px
      +below('s')
        height 215px
      +below('xs')
        height auto

.back
  padding 0.5rem 0 0.5rem

.links
  padding 2rem 0 2.33rem
  .link
    display block
    i
      font-size 0.8em !important
      padding-right 15px
