html
    +below('l')
        font-size 85%
    +below('m')
        font-size 50%
    +below('s')
        font-size 45%
    +below('xs')
        font-size 35%
body
    font-family $font-family-sans-serif;
    font-weight 300
    font-size $normal-font-size
    color black
    line-height 1.3
    margin-top 1rem
    // lost-utility edit

a
    color inherit
    text-decoration none

hr
    border none
    border-bottom 1px solid #ddd

.block-mobile
    +below('s')
        display block