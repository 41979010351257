/* Fonts
-------------------------------------------------- */

@font-face {
  font-family: 'geomanistregular';
  src: url('fonts/geomanist-medium/geomanist-medium-webfont.eot');
  src: url('fonts/geomanist-medium/geomanist-medium-webfont.eot?#iefix') format('embedded-opentype'),
          url('fonts/geomanist-medium/geomanist-medium-webfont.woff2') format('woff2'),
          url('fonts/geomanist-medium/geomanist-medium-webfont.woff') format('woff'),
          url('fonts/geomanist-medium/geomanist-medium-webfont.ttf') format('truetype'),
          url('fonts/geomanist-medium/geomanist-medium-webfont.svg#geomanistregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Customized
-------------------------------------------------- */
$beige = #f1e9ac
$blue = #3a7da3
$green = #6bc198
$orange = #ffb477
$violet = #8e3a57

/* Design
-------------------------------------------------- */
primary-color = $beige
$font-family-sans-serif = 'geomanistregular', 'Helvetica', Arial, sans-serif
$normal-font-size = 2.75rem
$big-font-size = 5.5rem

/* Bootstrap
-------------------------------------------------- */
// Allgemein
$border-radius-base = 2px
$input-border-focus = primary-color
$pagination-color = primary-color
$pagination-active-bg = primary-color
$pagination-active-border = primary-color

// Formulare
$state-danger-text = #db0100
$state-danger-border = $state-danger-text
$state-danger-bg = #f2dede

// Pagination
// $pagination-bg = transparent;
// $pagination-border = primary-color;
// $pagination-color = primary-color;
// $pagination-hover-color = primary-color;
// $pagination-hover-bg = transparent;
// $pagination-hover-border = primary-color;
// $pagination-active-bg = primary-color;
// $pagination-active-border = primary-color;

/* Rupture Media Queries
-------------------------------------------------- */
rupture.anti-overlap = 1px
rupture.scale = 0px 320px 420px 700px 1000px 1220px 1600px
rupture.scale-names = 'xxs' 'xs' 's' 'm' 'l' 'xl' 'hd'

/* Misc
-------------------------------------------------- */
offscreen-nav-width = 220px
