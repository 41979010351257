.archive
  &--items
    padding-top 0.5rem
    ul
      padding 0
      margin 0
      list-style none
  &--item
    text-transform: uppercase
    letter-spacing: 1px
    a
      color black
      &:hover
        color $orange

.back i
  font-size 0.8em !important
  padding-right 15px