@import 'bootstrap/forms'

.form-group
    position relative
    label
        margin-bottom 2px
        font-weight 500
        color black
        position absolute
        bottom 0
        right 0
    input
        font-family $font-family-sans-serif
        color black
        &::placeholder
            color #0000001a

    &.has-error label
        color $state-danger-text

        .inline-error
            background $state-danger-text
            color $state-danger-bg
            display inline-block
            padding 4px 6px
            line-height 1
            font-size .8em
            border-radius 4px
            margin-left 10px
            margin-bottom 1px

.form-control
    border none
    border-bottom 3px solid black
    border-radius 0
    box-shadow none
    font-size 1em
    padding 3px 10px
    height 50px
    margin 30px 0 45px
    color #adadad

.form-control:focus
    box-shadow none

.bs-callout
    padding 10px
    margin 20px 0
    border 1px solid #eee
    border-left-color #1b809e
    border-left-width 5px
    border-radius 3px