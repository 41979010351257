.main
    lost-utility clearfix
    color black
.content
    lost-column 1/1
    a
        color $beige
        &:hover
            color $violet
    i
        font-size 0.8em !important
    p
        margin 0
